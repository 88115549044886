import RequiresAuth from '@website/components/auth/RequiresAuth';
import { useContext } from 'react';
import Redirect from '@website/components/redirect';
import { Store } from '../../Store';

export default function CustomerIndexPage(): JSX.Element {
  const { state } = useContext(Store);

  return (
    <>
      <RequiresAuth>
        <Redirect to={`/me/${state.user.id}`} />
      </RequiresAuth>
    </>
  );
}
