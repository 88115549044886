import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingPage() {
  return (
    <div
      style={{ width: '100%', height: '10vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}
