import { useEffect } from 'react';
import Router from 'next/router';
import { Container } from 'react-bootstrap';
import LoadingPage from './utilities/LoadingPage';

export interface RedirectProps {
  to: string;
  push?: boolean;
}

export default function Redirect({
  to,
  push = true,
}: RedirectProps): JSX.Element {
  useEffect(() => {
    const method = push ? Router.push : Router.replace;
    method(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className="Home mt-3">
        <LoadingPage />
      </Container>
    </>
  );
}
