import { Store } from '@website/Store';
import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import AuthenticationModal from './AuthenticationModal';

/**
 * Requires Auth
 *
 * Wrap any component (e.g. a page) in this to show a login screen, if the user is not logged in.
 *
 * @example
 * // Page that requires authorization
 * export default function MyPage() {
 *   return (<RequiresAuth>Page Content</RequiresAuth>);
 * }
 *
 */
export default function RequiresAuth({
  authModalTitle,
  children,
  onSuccess,
}: RequiresAuthProps) {
  const { state } = useContext(Store);

  // If still getting login state, show a spinner
  if (state.isAuthenticating) {
    return <Spinner animation="grow" />;
  }

  // If authenticated, just show children
  if (state.isAuthenticated) {
    return <>{children}</>;
  }

  // If unauthenticated, show login
  return (
    <AuthenticationModal
      onSuccess={onSuccess}
      showModal
      authModalTitle={authModalTitle}
    />
  );
}

export interface RequiresAuthProps {
  children: any;
  authModalTitle?: string;
  onSuccess?: () => void;
}
